<template>
  <div class="content">
    <el-row>
      <el-col :span="5" style="border-right:1px solid #F1F1F1">
        <div class="TreeBox">
          <div class="detil-code">片区查看</div>
          <div class="clears">
            <el-input
              v-model="filterText"
              prefix-icon="el-icon-search"
              size="mini"
              placeholder="输入关键字进行过滤"
              clearable
            >
            </el-input>
            <el-button size="mini" class="buttons" type="primary"
              >搜索</el-button
            >
          </div>
          <el-tree
            ref="tree"
            node-key="id"
            class="filter-tree"
            :data="treedata"
            :props="defaultProps"
            :default-expanded-keys="[8]"
            :default-expand-all="false"
            :filter-node-method="filterNode"
            @node-click="click"
          >
          </el-tree>
        </div>
      </el-col>
      <el-col :span="19" v-if="!dialogVisible">
        <div class="TableBox">
          <el-form class="SearchBox" :inline="true">
            <el-form-item label="用户分类">
              <el-select
                v-model="pageParams.userType"
                placeholder="请选择"
                size="mini"
                clearable
                filterable
                @change="search"
              >
                <el-option
                  v-for="(item, index) in userTypeList"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="安检员">
              <el-select
                  clearable
                  filterable
                v-model="pageParams.userId"
                placeholder="请选择"
                size="mini"
                  @change="search"
              >
                <el-option
                  v-for="(item, index) in peopleList"
                  :key="index"
                  :label="item.realname"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="启用状态">
              <el-select
                  @change="search"
                  clearable
                  filterable
                v-model="pageParams.status"
                placeholder="请选择"
                size="mini"
              >
                <el-option
                  v-for="(item, index) in statusList"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <!-- <el-select
                v-model="chooseType"
                style="width: 100px; margin: 6px 10px"
                placeholder="请选择"
                size="mini"
                @change="chooseTypeHandle"
            >
              <el-option
                  v-for="item in dataList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              >
              </el-option>
            </el-select>
            <el-input v-if="chooseType==1" v-model="pageParams.userName" size="mini" placeholder="请输入关键字" style="width: 150px;margin-right: 10px;margin-top: 6px"></el-input>
            <el-input v-if="chooseType==2" v-model="pageParams.contact" size="mini" placeholder="请输入关键字" style="width: 150px;margin-right: 10px;margin-top: 6px"></el-input>
            <el-input v-if="chooseType==3" v-model="pageParams.userPhone" size="mini" placeholder="请输入关键字" style="width: 150px;margin-right: 10px;margin-top: 6px"></el-input>
            <el-input v-if="chooseType==4" v-model="pageParams.address" size="mini" placeholder="请输入关键字" style="width: 150px;margin-right: 10px;margin-top: 6px"></el-input>
            <el-input v-if="chooseType==5" v-model="pageParams.sheetSteel" size="mini" placeholder="请输入关键字" style="width: 150px;margin-right: 10px;margin-top: 6px"></el-input> -->
            <el-form-item label="" label-width="0px" class="selectType">
							<el-input
								placeholder="请输入关键字"
                size="mini"
								v-model="pageParams[keyword]"
							>
								<el-select
									placeholder="请选择"
									v-model="chooseType"
									slot="prepend"
                  filterable
                  @change="chooseTypeHandle"
								>
									<el-option
                    v-for="item in dataList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
              </el-option>
								</el-select>
							</el-input>
						</el-form-item>
            <el-button
              size="mini"
              type="primary"
              @click="search"
              style="height: 30px; margin-top: 5px"
              >查询</el-button
            >
            <el-button
              size="mini"
              style="height: 30px; margin-top: 5px"
              @click="restSearch"
              type="warning"
              >重置</el-button
            >
            <el-button
                v-if="$anthButtons.getButtonAuth('fmyhdochu')"
              size="mini"
              @click="exportUser"
              type="text"
              style="
                border: 1px solid #1082ff;
                color: #1082ff;
                padding: 0 14px;
                height: 30px;
                margin-top: 5px;
              "
              plain
            >
              导出</el-button
            >
          </el-form>
          <div class="TableTool" style="margin-top: 10px">
            <div class="TableToolLeft">
              <!-- <el-button  size="mini" @click="addUser">添加</el-button> -->
              <el-button
                  v-if="$anthButtons.getButtonAuth('fmyhplsc')"
                size="mini"
                @click="del(0)"
                type="text"
                style="
                  border: 1px solid #f56c6c;
                  color: #f52424;
                  padding: 0 14px;
                  height: 30px;
                "
                plain
                >批量删除</el-button
              >
              <el-button
                  v-if="$anthButtons.getButtonAuth('fmyhqbsc')"
                size="mini"
                @click="delALL( 1)"
                type="text"
                style="
                  border: 1px solid #f56c6c;
                  color: #f52424;
                  padding: 0 14px;
                  height: 30px;
                "
                plain
                >全部删除</el-button
              >
              <el-button
                  v-if="$anthButtons.getButtonAuth('fmyhplcl')"
                size="mini"
                @click="batch"
                type="text"
                style="
                  border: 1px solid #1082ff;
                  color: #1082ff;
                  padding: 0 14px;
                  height: 30px;
                "
                plain
                >批量处理</el-button
              >
            </div>
            <div class="TableToolRight">
              <el-button
                  v-if="$anthButtons.getButtonAuth('fmyhtianjia')"
                size="mini"
                @click="addUser"
                type="text"
                style="
                  border: 1px solid #1082ff;
                  color: #1082ff;
                  padding: 0 14px;
                  height: 30px;
                "
                plain
                >添加</el-button
              >
              <!-- <el-upload
                  v-if="$anthButtons.getButtonAuth('fmhdaoru')"
                  class="upload-demo"
                  ref="upload"
                  :http-request="handlePreview"
                  accept=".xls,.xlsx"
                  action="#"
                  :auto-upload="true"
                  :show-file-list="false"
              >
                <el-button slot="trigger" style="margin: 0 10px;border: 1px solid #1082ff;color: #1082ff;padding: 0 14px;height: 30px;" plain>导入用户档案</el-button>
              </el-upload> -->
<!--              <el-button-->
<!--                size="mini"-->
<!--                @click="fileDialog = true"-->
<!--                type="text"-->
<!--                style="border: 1px solid #1082ff;color: #1082ff;padding: 0 14px;height: 30px;"-->
<!--                plain-->
<!--                >导入用户档案</el-button-->
<!--              >-->

              <!-- <el-button @click="download"
                         v-if="$anthButtons.getButtonAuth('fmyhxiazai')"
                size="mini"
                type="text"
                style="
                  border: 1px solid #1082ff;
                  color: #1082ff;
                  padding: 0 14px;
                  height: 30px;
                "
                plain
                >下载导入模板</el-button
              > -->
            </div>
          </div>
          <el-table
            :data="userList"
            height="calc(100vh - 380px)"
            style="margin-top: 10px"
            border
            :header-cell-style="{
              color: '#333',
              fontFamily: 'MicrosoftYaHeiUI',
              fontSize: '14px',
              fontWeight: 900,
              background: '#f8f8f9',
              textAlign:'left',
            }"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="50"> </el-table-column>
            <el-table-column
              type="index"
              label="序号"
              width="60"
            ></el-table-column>
            <el-table-column
              prop="userName"
              label="用户名称"
              width="150"
            >
              <template slot-scope="{row}">
                <div  slot="reference" class="twoLines">
                  {{row.userName}}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="contact"
              label="联系人"
              width="90"
            >
              <template slot-scope="{row}">
                <div  slot="reference" class="twoLines">
                  {{row.contact}}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="userPhone"
              label="电话"
              width="110"
            >
              <template slot-scope="{row}">
                <div  slot="reference" class="twoLines">
                  {{row.userPhone}}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="userTypeName"
              show-overflow-tooltip
              width="110"
              label="用户分类"
            ></el-table-column>
            <el-table-column prop="addrName" label="片区" width="150">
              <template slot-scope="{row}">
                <el-tooltip  class="item" placement="top-start" effect="dark" :content="row.addrName">
                  <!-- <div  slot="reference" class="twoLines"> -->
                   <div class="twoLines"> {{row.addrName}}</div>
                  <!-- </div> -->
                </el-tooltip>
                
                <!-- <el-popover placement="top-start" trigger="hover" :content="row.deptName">
                  <div  slot="reference" class="twoLines">
                    {{row.deptName}}
                  </div>
                </el-popover> -->
                
              </template>
            </el-table-column>
            <el-table-column prop="address" label="地址" width="150">
              <template slot-scope="{row}">
                <el-tooltip class="item" placement="top-start" effect="dark"  :content="row.address">
                  <!-- <div  slot="reference" class="twoLines"> -->
                    <div class="twoLines">{{row.address}}</div>
                  <!-- </div> -->
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              prop="userIdnumber"
              label="身份证号"
              width="180"
            >
              <!-- <template slot-scope="{row}"> -->
                <!-- <el-tooltip class="item" placement="top-start"  :content="row.userIdnumber"> -->
                  <!-- <div  slot="reference" class="twoLines"> -->
                    <!-- <div class="twoLines">{{row.userIdnumber}}</div> -->
                  <!-- </div> -->
                <!-- </el-tooltip> -->
              <!-- </template> -->

            </el-table-column>
            <el-table-column
              prop="businessLicenseNumber"
              label="营业执照号"
              width="150"
            >
              <!-- <template slot-scope="{row}">
                <el-tooltip class="item" placement="top-start"  :content="row.businessLicenseNumber"> -->
                  <!-- <div  slot="reference" class="twoLines"> -->
                    <!-- <div class="twoLines">{{row.businessLicenseNumber}}</div> -->
                  <!-- </div> -->
                <!-- </el-tooltip>
              </template> -->
            </el-table-column>
            <!-- <el-table-column
                align="center"
                prop="recentlyDate"
                label="最近购气日期"
                width="150"
            >
              <template slot-scope="{row}">
                <el-tooltip class="item" placement="top-start"  :content="row.recentlyDate">
                   <div class="twoLines"> {{row.recentlyDate}}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
                prop=""
                label="总用气量"
                width="150"
            >
            </el-table-column> -->
            <el-table-column
              prop="tableNumber"
              label="表具"
              fixed="right"
            >
              <template slot-scope="{ row }">
                <div
                  style="color: #1082ff; cursor: pointer"
                  @click="goPath('/home/TableWith',row.id)"
                >
                 <div class="twoLines"> {{ row.tableCount }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="deptName"
              label="负责部门"
              fixed="right"
              width="100"
            >
              <!-- <template slot-scope="{row}"> -->
                <!-- <el-popover placement="top-start" trigger="hover" :content="row.deptName">
                  <div  slot="reference" class="twoLines">
                    {{row.deptName}}
                  </div>
                </el-popover> -->
                <!-- <el-tooltip class="item" effect="dark" :content="row.deptName" placement="top-start">
                  <div class="twoLines">{{row.deptName}}</div>
                </el-tooltip>
              </template> -->
            </el-table-column>
            <el-table-column
              prop="inspectorName"
              label="安检员"
              fixed="right"
              width="150"
            >
              <template slot-scope="{row}">
                <!-- <el-popover placement="top-start" trigger="hover" :content="row.inspectorName">
                  <div  slot="reference" class="twoLines" v-if="row.inspectorName">
                    <p>
                      {{fillterName(row.inspectorName)[0]}}
                    </p>
                    <p>
                      ({{fillterName(row.inspectorName)[1]}}
                    </p>
                  </div>
                </el-popover> -->
                <!-- <el-tooltip class="item" effect="dark" :content="row.inspectorName" placement="top-start"> -->
                  <div  class="twoLines" v-if="row.inspectorName">
                    <p>
                      {{fillterName(row.inspectorName)[0]}}
                    <br/>
                      ({{fillterName(row.inspectorName)[1]}}
                    </p>
                  </div>
                <!-- </el-tooltip> -->
              </template>
            </el-table-column>
            <el-table-column
              prop="status"
              label="启用状态"
              fixed="right"
              width="90px"
            >
              <template slot-scope="{ row }">
                <el-tag v-if="row.status==1" type="success">已启用 </el-tag>
                <el-tag v-if="row.status==2" type="warning">停用安检 </el-tag>
                <el-tag v-if="row.status==3" type="warning">停用不安检 </el-tag>
                <el-tag v-if="row.status==4" type="danger">已销户 </el-tag>
              </template>
            </el-table-column>
            <el-table-column
              prop="State"
              label="操作"
              width="180px"
              fixed="right"
            >
              <template slot-scope="{ row }">
                <div class="TableActionBar">
                  <el-button
                      v-if="$anthButtons.getButtonAuth('fmyhbianji')"
                    type="primary"
                    size="mini"
                    style="margin: 0 10px"
                    @click="compile(row)"
                    >编辑</el-button
                  >
                  <el-dropdown @command="(command) => exchange(command, row)">
                    <el-button
                      type="text"
                      size="mini"
                      style="
                        border: 1px solid #1082ff;
                        color: #1082ff;
                        padding: 0 11px;
                        height: 30px;
                      "
                      >更多<i class="el-icon-caret-bottom el-icon--right"></i
                    ></el-button>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="启用" v-if="row.status!=1&&row.status!=4 && $anthButtons.getButtonAuth('fmyhtingyong')">启用</el-dropdown-item>
                      <el-dropdown-item command="停用" v-if="row.status==1 && $anthButtons.getButtonAuth('fmyhtingyong')">停用</el-dropdown-item>
                      <el-dropdown-item command="销户" v-if="row.status!=4 && $anthButtons.getButtonAuth('fmyhxiaohu')">销户</el-dropdown-item>
                      <el-dropdown-item
                          v-if="$anthButtons.getButtonAuth('fmyhbjjl')"
                          command="编辑记录"
                        >编辑记录</el-dropdown-item
                      >
                      <el-dropdown-item command="换表记录"
                                        v-if="$anthButtons.getButtonAuth('fmyhhbjl')"
                        >换表记录</el-dropdown-item
                      >
                      <el-dropdown-item command="安检记录"
                                        v-if="$anthButtons.getButtonAuth('fmjlajjl')"
                        >安检记录</el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div style="padding: 20px 0">
            <el-pagination
              background
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              :page-sizes="[30, 50, 100]"
              :page-size="pageParams.size"
              :current-page="pageParams.current"
              @size-change="handleSizeChange"
              @current-change="currentChange"
            >
            </el-pagination>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 批量删除 -->
    <el-dialog
      :visible.sync="dialogBatch"
      width="20%"
      :close-on-click-modal="false"
      @close="handleClose"
    >
      <el-form class="SearchBox" ref="Batchform" :model="Batchform" :rules="batchRule">
        <el-form-item label="行政区域" label-width="80px" prop="addrId">
          <el-select v-model="Batchform.addrId" placeholder="请选择" size="mini" @change="chooseUser(Batchform.addrId)" style="width: 100%" filterable>
            <el-option
              v-for="(item, index) in areaList"
              :key="index"
              :label="item.addrName"
              :value="item.id+''"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="安检员" label-width="80px" prop="inspectorId">
          <el-select v-model="Batchform.inspectorId" placeholder="请选择" size="mini" style="width: 100%" filterable>
            <el-option
                v-for="(item, index) in peopleListTwo"
                :key="index"
                :label="item.realname+'('+item.mobile+')'"
                :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button style="margin-right: 10px" @click="dialogBatch = false">取消</el-button>
          <el-button type="primary" @click="submitBatch('Batchform')">确定</el-button>
      </span>
    </el-dialog>
    <!-- 添加用户 -->
    <el-dialog
      :title="userTitle"
      :visible.sync="addUserDialog"
      width="520px"
      :close-on-click-modal="false"
      @close="UserClose"
    >
      <el-form class="SearchBox" ref="form" :model="form" :rules="rule">
        <el-form-item label="用户名称：" label-width="145px" prop="userName">
          <el-input
            v-model="form.userName"
            placeholder="请输入内容"
            style="width: 308px"
            :maxlength="30"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系人：" label-width="145px" prop="contact">
          <el-input
            v-model="form.contact"
            placeholder="请输入内容"
            style="width: 308px"
            :maxlength="30"
          ></el-input>
        </el-form-item>
        <el-form-item label="电话：" label-width="145px" prop="userPhone">
          <el-input
            v-model="form.userPhone"
            placeholder="请输入内容"
            style="width: 308px"
            :maxlength="30"
          ></el-input>
        </el-form-item>
        <el-form-item label="身份证号：" label-width="145px" prop="userIdnumber">
          <el-input
            v-model="form.userIdnumber"
            placeholder="请输入内容"
            style="width: 308px"
            :maxlength="30"
          ></el-input>
        </el-form-item>
        <el-form-item label="营业执照号：" label-width="145px">
          <el-input
            v-model="form.businessLicenseNumber"
            placeholder="请输入内容"
            style="width: 308px"
            :maxlength="30"
          ></el-input>
        </el-form-item>
        <el-form-item label="用户分类：" label-width="145px" prop="userType">
          <el-select
              v-model="form.userType"
              placeholder="请选择"
              clearable
              filterable
              style="width: 308px"
          >
            <el-option
                v-for="(item, index) in userTypeList"
                :key="index"
                :label="item.name"
                :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="片区：" label-width="145px" prop="addrId">
          <el-select
            v-model="form.addrId"
            placeholder="请选择"
            style="width: 308px"
            filterable
            @change="chooseUser(form.addrId)"
          >
            <el-option
              v-for="(item, index) in areaList"
              :key="index"
              :label="item.addrName"
              :value="item.id+''"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="地址：" label-width="145px" prop="address">
          <el-input
            v-model="form.address"
            @click.native="clickAddress"
            placeholder="请选择地址"
            style="width: 308px"
          ></el-input>
        </el-form-item>
        <!-- prop="userId" -->
        <el-form-item label="安检员：" label-width="145px" >
          <el-select
              clearable
              filterable
              v-model="form.userId"
              placeholder="请选择"
              style="width: 308px"
          >
            <el-option
                v-for="(item, index) in peopleListTwo"
                :key="index"
                :label="item.realname+'('+item.mobile+')'"
                :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button style="margin-right: 10px" @click="addUserDialog = false">取消</el-button>
          <el-button type="primary" @click="submitUpload('form')">确定</el-button>
      </span>
    </el-dialog>
    <!-- 地图组件 -->
    <el-dialog
      :visible.sync="showModel"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <mapDialog ref="mapDialog" @closeModal="closeModel" :newForm="newForm" :point="point"></mapDialog>
    </el-dialog>
    <!-- 导出中 -->
    <el-dialog title="导出中..." :visible.sync="deriveVisible" width="30%">
			<el-table v-loading="loading" empty-text="导出中"> </el-table>
			<span slot="footer" class="dialog-footer">
				<el-button @click="cancel">取 消</el-button>
			</span>
		</el-dialog>
  </div>
</template>
<script>
import {
  addrDropDown,
  findTreeList,
  inspectorDropDown,
  userPageList,
  userTypeDropDown,
  userInsertOrUpdate,
  userUpdateStatus,
  userBatchDelete, userBatchSet,
  userAllDelete
} from "@/RequestPort/induction";
import mapDialog from "./mapDialog";
import getNowFormatDate from "@/common/js/nowDate";
import {importUserExcel, materialImport} from "../../RequestPort/cost/cost";
import {exportUserExcel, selectInspectorsByAddr} from "../../RequestPort/induction";
import { exportMethod } from '../../common/js/exportExcel'
export default {
  name: "User",
  components: { mapDialog },
  data() {
    return {
      newForm:'',
      point:'',
      keyword:'userName',
      chooseType:1,
      dataList:[
        {label:"用户名称",value:1},{label:"联系人",value:2},{label:"电话",value:3},{label:"地址",value:4},{label:"表编号",value:5}
      ],
      userTypeList:[],
      // 人员下拉
      peopleList:[],
      areaList:[],
      statusList:[
        {id:1,name:'已启用'},{id:2,name:'停用安检'},{id:3,name:'停用不安检'},{id:4,name:"已销户"}
      ],
      pageParams: {
        current: 1,
        size: 100,
        status: "",
        userId: "",
        userName: "",
        contact: '',
        userPhone:'',
        address:'',
        sheetSteel:''
      },
      updataUserId: null,
      form: {
        userPhone:"",
        contact:'',
        userIdnumber:"",
        businessLicenseNumber:'',
        userName:'',
        addrId:'',
        address:'',
        userId: "",
        userType: '',
      },
      Batchform:{
        addrId:"",
        inspectorId:"",
      },
      streetList: [],
      updataStatus: 0,
      dialogVisible: false,
      total: null,
      // 列表数据
      userList: [{ name: "11", phone: 1 }],
      userStatus: [
        { label: "启用", id: "1" },
        { label: "禁用", id: "0" },
      ],
      roleArray_group: [],
      filterText: "",
      treedata: [],
      defaultProps: {
        children: "children",
        label: "name",
      },
      batchRule:{
        addrId: [
          { required: true, message: "请选择", trigger: "blur" },
        ],
        inspectorId: [
          { required: true, message: "请选择", trigger: "blur" },
        ],
      },
      rule: {
        userName: [
          { required: true, message: "请输入用户名称", trigger: "blur" },
          { max: 25, message: "超出最大限制", trigger: "change" },
        ],
        contact: [
          { required: true, message: "请输入联系人", trigger: "blur" },
          { max: 25, message: "超出最大限制", trigger: "change" },
        ],
        addrId: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        userIdnumber: [
          {
            message: "请输入正确的身份证号",
            trigger: ["change"],
            pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/, },
        ],
        address: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        userType: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        userId: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        userPhone: [
          { required: true,
            message: "请输入手机号",
            trigger: "blur" },
        ],
      },
      // 批量删除弹窗
      dialogBatch: false,
      deriveVisible: false,
      loading:true,
      // 批量删除对象
      from:{},
      recursion: [],
      tableDataList: [],
      fileList: [],
      fileDialog: false,
      // 复选的选中数据
      checkAll: [],
      // 地图组件
      showModel: false,
      // 添加用户弹窗
      addUserDialog: false,
      // 用户弹窗
      userTitle: "添加用户",
      // 选择片区后的人员
      peopleListTwo:[]
    };
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
    '$store.getters.deriveVisible'(val) {
        this.deriveVisible = val
    },
  },
  mounted() {
    // this.getRoleIdsDic();
    //  获取树
    findTreeList().then(res => {
        this.treedata = res.data
        this.pageParams.homeCode = res.data[0].id
        this.loadList(this.pageParams)
      })
      // .catch(err => {
      //   this.$message.error(err.message)
      // })
    userTypeDropDown().then(res=>{
      this.userTypeList = res.data
    })
    inspectorDropDown().then(res=>{
      this.peopleList = res.data
    })
    addrDropDown().then(res=>{
      this.areaList = res.data
    })
  },
  methods: {
    chooseTypeHandle(val){
      if(val==1){
        this.keyword = 'userName'
        this.pageParams.contact=''
        this.pageParams.userPhone=''
        this.pageParams.address=''
        this.pageParams.sheetSteel=''
      }else if(val==2){
        this.keyword = 'contact'
        this.pageParams.userName=''
        this.pageParams.userPhone=''
        this.pageParams.address=''
        this.pageParams.sheetSteel=''
      }else if(val==3){
        this.keyword = 'userPhone'
        this.pageParams.contact=''
        this.pageParams.userName=''
        this.pageParams.address=''
        this.pageParams.sheetSteel=''
      }else if(val==4){
        this.keyword = 'address'
        this.pageParams.userPhone=''
        this.pageParams.contactName=''
        this.pageParams.userName=''
        this.pageParams.sheetSteel=''
      }else if(val==5){
        this.keyword = 'sheetSteel'
        this.pageParams.userPhone=''
        this.pageParams.contactName=''
        this.pageParams.address=''
        this.pageParams.userName=''
      }
    },
    fillterName(name){
        return name.split('(')
    },
    clickAddress(){
      this.showModel = true
      if(this.form.address){
        this.newForm=this.form.address
        this.point=this.form.addressCoord
        console.log(this.form.addressCoord)
        this.$nextTick(()=>{
          this.$refs.mapDialog.openL(this.form.addressCoord)
        })

      }
    },
    /**
     * 导出
     */
    exportUser(){
      let arr = []
      this.checkAll.forEach(item=>{
        arr.push(item.id)
      })
      this.deriveVisible = true
      this.$store.commit('setderiveVisible',true)
      // let obj = {
      //   userType:this.pageParams.userType,
      //   userId:this.pageParams.userId,
      //   status: this.pageParams.status,
      //   contact: this.pageParams.contact,
      //   userPhone:this.pageParams.userPhone,
      //   address:this.pageParams.address,
      //   sheetSteel:this.pageParams.sheetSteel,
      //   userIds:arr
      // }
      exportUserExcel(this.pageParams).then(res=>{
        exportMethod(res, '非居民用户1')
        this.deriveVisible = false
      })
    },
     cancel(){
      this.deriveVisible = false
      this.$store.commit('setderiveVisible',false)
      window.stop()
    },
    /**
     * 选择片区
     */
    chooseUser(value){
      if(this.Batchform.inspectorId!=''){
        this.Batchform.inspectorId = ''
      }
      if(this.form.userId!=''){
        this.form.userId = ''
      }
      selectInspectorsByAddr({addrId:value}).then(res=>{
        this.peopleListTwo = res.data
      })
    },
    showTable(){
      this.fileDialog = false
      this.loadList(this.pageParams)
    },
    async handlePreview(file) {
      let uploadObj = new FormData();
      uploadObj.append("file", file.file);
      try {
        let { data } = await importUserExcel(uploadObj);
        this.tableDataList = data;
        this.loadList(this.pageParams)
      } catch (e) {
        this.tableDataList = []
      }
    },
    /**
     * 下载导入模板
     */
    download(){
      const elink = document.createElement("a");

      // elink.href ="https://gas-dev.oss-cn-beijing.aliyuncs.com/ZYMenuIcon/gsymb2.xlsx";
      elink.href ="https://gas-dev.oss-cn-beijing.aliyuncs.com/THZL/fymb.xlsx"
      const fileName = getNowFormatDate() + ".xls";

      elink.setAttribute("download", fileName);

      elink.style.display = "none";

      document.body.appendChild(elink);

      setTimeout(() => {
        elink.click();

        document.body.removeChild(elink);
      }, 66);
    },
    submitBatch(form){
      this.$refs[form].validate((valid) => {
        if (!valid) return false
        let arr = []
        this.checkAll.forEach(item=>{
          arr.push(item.id)
        })
        this.Batchform.userIds = arr
        userBatchSet(this.Batchform).then(res=>{
          if(res.code===200){
            this.$message.success(res.data)
            this.dialogBatch = false
            this.loadList(this.pageParams)
          }else{
            this.$message.error(res.data)
          }
        })
      })
    },
    submitUpload(form){
      this.$refs[form].validate((valid) => {
        if (!valid) return false
        userInsertOrUpdate(this.form).then(res=>{
         if(res.code===200){
           this.$message.success(res.data)
           this.addUserDialog = false
           this.loadList(this.pageParams)
         }else{
           this.$message.error(res.data)
         }
        })
      })
      // userInsertOrUpdate
    },
    /**
     * 换表
     */
    exchange(command, row) {
      if (command == "启用") {
        this.$confirm("确认启用该用户？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "再想想",
          closeOnClickModal:false,
          type: "warning",
        })
            .then(() => {
              userUpdateStatus({userId:row.id,status:1}).then(res=>{
                if(res.code===200){
                  this.$message.success(res.data)
                  this.loadList(this.pageParams)
                }else {
                  this.$message.error(res.data)
                }
              })
            })
            .catch(() => {
              console.log("不再启用");
            });
      }
      if (command == "停用") {
        this.$confirm("确认该用户停用后是否继续执行安检", "提示", {
          confirmButtonText: "继续安检",
          distinguishCancelAndClose:true,
          cancelButtonText: "不再安检",
          closeOnClickModal:false,
          type: "warning",
        })
          .then(() => {
            userUpdateStatus({userId:row.id,status:2}).then(res=>{
              if(res.code===200){
                this.$message.success(res.data)
                this.loadList(this.pageParams)
              }else {
                this.$message.error(res.data)
              }
            })
          })
          .catch(action => {
            //判断是自定义的取消按钮，还是关闭弹框
            console.log(action)
            if(action==='cancel'){
              userUpdateStatus({userId:row.id,status:3}).then(res=>{
                if(res.code===200){
                  this.$message.success(res.data)
                  this.loadList(this.pageParams)
                }else {
                  this.$message.error(res.data)
                }
              })
            }
          });
      }
      if (command == "销户") {
        this.$confirm("是否确认该用户销户，销户后不能恢复", "提示", {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          closeOnClickModal:false,
          type: "warning",
        })
          .then(() => {
            userUpdateStatus({userId:row.id,status:4}).then(res=>{
              if(res.code===200){
                this.$message.success(res.data)
                this.loadList(this.pageParams)
              }else {
                this.$message.error(res.data)
              }
            })
          })
          .catch(() => {
            console.log("取消销户");
          });
      }
      if (command == "换表记录") {
        // this.$router.push({
        //   path: "/home/record/recordTable",
        //   query: { id: row.id },
        // });
        let chakan = this.$router.resolve({
					path: '/home/record/recordTable',      //跳转目标窗口的地址
					query: { id: row.id, type: 'unUser' } 
				})
				window.open(chakan.href);
      }
      if(command == '编辑记录') {
        // this.$router.push({
        //   path: "/home/record/recordEdit",
        //   query: { id: row.id },
        // });
        let chakan = this.$router.resolve({
					path: '/home/record/recordEdit',      //跳转目标窗口的地址
					query: { id: row.id } 
				})
				window.open(chakan.href);
      }
      if(command == '安检记录') {
        // this.$router.push({
        //   path: "/home/record/recordSecurity",
        //   query: { id: row.id },
        // });
        let chakan = this.$router.resolve({
					path: '/home/record/recordSecurity',      //跳转目标窗口的地址
					query: { id: row.id } 
				})
				window.open(chakan.href);
      }
    },
    /**
     *    功能描述： 编辑弹窗按钮
     *    开发人：why
     *    开时间：2022.5.5
     */
    compile(row) {
      if(this.$refs.form){
        this.$refs.form.resetFields()
      }
      let obj = JSON.parse(JSON.stringify(row))
      this.form = obj
      this.form.addrId =row.addrId +''
      if(row.addressCoord){
        this.form.lat =row.addressCoord.split(' ')[1]
        this.form.lng =row.addressCoord.split(' ')[0]

      }
      selectInspectorsByAddr({addrId:obj.addrId}).then(res=>{
        this.peopleListTwo = res.data
      })
      this.userTitle = "编辑用户";
      this.addUserDialog = true;
    },
    /**
     *    功能描述： 批量删除
     *    开发人：why
     *    开时间：2022.5.5
     */
    batch() {
      let that = this
      if (this.checkAll.length == 0) {
        that.$message.error("请先勾选数据");
        return;
      }
      this.dialogBatch = true;
    },
    /**
     *    功能描述： 跳转页面
     *    开发人：why
     *    开时间：2022.5.17
     */
    goPath(path, id, disable) {
      this.$router.push({ path: path, query: { id: id, disable: disable } });
    },
    /**
     *    功能描述： 批量删除关闭弹窗
     *    开发人：why
     *    开时间：2022.5.5
     */
    handleClose() {
      this.Batchform = {
        addrId:"",
        inspectorId:"",
      };
      inspectorDropDown().then(res=>{
        this.peopleList = res.data
      })
      this.dialogBatch = false;
    },
    /**
     *    功能描述： 添加用户关闭弹窗
     *    开发人：why
     *    开时间：2022.5.17
     */
    UserClose() {
      this.form = {
        userPhone:"",
        contact:'',
        userIdnumber:"",
        businessLicenseNumber:'',
        userName:'',
        addrId:'',
        address:'',
        userId: "",
        userType: '',
      };
      if(this.$refs.form){
        this.$refs.form.clearValidate()
      }
      this.addUserDialog = false;
    },
    /**
     *    功能描述： 地图选择位置
     *    开发人：why
     *    开时间：2022.5.16
     */
    closeModel(address, lnglat) {
    // this.showModel = false;
      if (!address && !lnglat) {
        this.showModel = false;
      } else {
        console.log(lnglat);
        this.form.lat = lnglat[1];
        this.form.lng = lnglat[0];
        this.form.addressCoord = lnglat[0]+' '+lnglat[1]
        this.form.address = address;
        this.showModel = false;
        this.$forceUpdate();
      }
    },
    handleChange(value) {
      console.log(value);
    },
    /**
     *    功能描述： 全选
     *    开发人：why
     */
    handleSelectionChange(val) {
      this.checkAll = val;
    },
    recursionFun(e, pid) {
      if (e.children) {
        e.children.forEach((item, index) => {
          this.recursionFun(item, e.id);
        });
        this.recursion.push(e);
      } else {
        this.recursion.push(e);
        return;
      }
    },
    // 重置查询条件
    restSearch() {
      this.pageParams = {
        current: 1,
        size: 100,
        userName: "",
        contact: '',
        userPhone:'',
        address:'',
        sheetSteel:''
      };
      this.loadList(this.pageParams);
    },
    // 返回
    close(val) {
      this.dialogVisible = !this.dialogVisible; //监听值发生变化就关闭它
    },
    /**
     * 批量处理
     */
    del(val) {
      let that = this
      if (this.checkAll.length == 0 && !val) {
        that.$message.error("请先勾选数据");
        return;
      }
      let text = val ? "是否确认删除列表中所有数据" : "是否确认删除所选数据";
      this.$confirm(text, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal:false,
        type: "warning",
      }).then(() => {
        let arr =[]
        this.checkAll.forEach(item=>{
          arr.push(item.id)
        })
        if(val){
          userBatchDelete([]).then(res=>{
            this.loadList(this.pageParams);
          })
        }else {
          userBatchDelete(arr).then((e) => {
            if (e.code === 200) {
              that.$message.success(e.data);
            } else {
              that.$message.error(e.data);
            }
            this.loadList(this.pageParams);
          }).catch(arr => {
          this.loadList(this.pageParams);
        });
        }

      });
    },
    /**
     * 全部删除
     */
    delALL(val){
      let that = this
      if (this.checkAll.length == 0 && !val) {
        that.$message.error("请先勾选数据");
        return;
      }
      let text = val ? "是否确认删除列表中所有数据" : "是否确认删除所选数据";
      this.$confirm(text, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal:false,
        type: "warning",
      }).then(() => {
        userAllDelete(this.pageParams).then((e) => {
          if (e.code === 200) {
            that.$message.success(e.data);
          } else {
            that.$message.error(e.data);
          }
          this.loadList(this.pageParams);
        }).catch(arr => {
          this.loadList(this.pageParams);
        });
      });
    },
    updataUser(row) {
      this.updataStatus = 1;
      this.updataUserId = row.id;
      findUserById({ id: row.id }).then((res) => {
        this.form = res.data;

        console.log(this.form, this.roleArray_group);
      });
      this.dialogVisible = true;
    },
    submitfrom(form) {
      this.$refs[form].validate((valid) => {
        if (!valid) return false;
        let ajaxData = {};
        this.form.roleIds = Array.from(new Set(this.form.roleIds)); //去重
        Object.assign(ajaxData, this.form);
        ajaxData.homeCode = this.form.homeCode;
        saveUser(ajaxData)
          .then((e) => {
            if (e.code === 200) {
              this.$message.success(e.msg);
              this.dialogVisible = false;
              this.loadList(this.pageParams);
            } else {
              this.$message.error(e.msg);
            }
          })
          .catch((err) => {
            this.$message.error(err);
          });
      });
    },
    updatasubmitfrom(form) {
      this.$refs[form].validate((valid) => {
        if (!valid) return false;
        let ajaxData = {};
        this.form.roleIds = Array.from(new Set(this.form.roleIds)); //去重
        ajaxData.homeCode = this.form.homeCode;
        Object.assign(ajaxData, this.form);
        updateUser(ajaxData).then((e) => {
          if (e.code === 200) {
            this.$message.success(e);
            this.dialogVisible = false;
            this.loadList(this.pageParams);
          } else {
            this.$message.error(e);
          }
        });
      });
    },
    /**
     * 添加用户弹窗按钮
     */
    addUser() {
      this.userTitle = "添加用户";
      this.addUserDialog = !this.addUserDialog;
      this.point = ''
      this.newForm = ''
      if(this.$refs.form){
      this.$refs.form.clearValidate()}
    },
    loadList(obj) {
      userPageList(obj).then(res=>{
        this.userList = res.data.records
        this.total = res.data.total
        if (this.pageParams.current > 1 && res.data.records.length == 0) {
          this.pageParams.current--
          this.loadList()
        }
      })
    },
    search() {
      this.pageParams.current = 1;
      this.loadList(this.pageParams);
    },
    currentChange(current) {
      this.pageParams.current = current;
      this.loadList(this.pageParams);
    },
    handleSizeChange(val) {
      this.pageParams.size = val;
      this.currentChange(1);
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    click(obj) {
      if(obj.id.length>10){//点击的是片区
        this.pageParams.addrCode = obj.id

        this.pageParams.deptId=''

      }else{
        if(obj.id==1||obj.id==8){
          this.pageParams.deptId = ''
        }else {
          this.pageParams.deptId = obj.id

        }
        this.pageParams.addrCode=''
      }
      this.pageParams.current = 1;
      this.loadList(this.pageParams);
    },
    statusChange(val, row) {
      const statusText = val === 0 ? "禁用" : "开启";
      this.$confirm(`是否${statusText}此用户账号?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal:false,
        type: "warning",
      })
        .then(() => {
          usereditStatus({ id: row.id, generalStatus: val })
            .then(() => {
              this.$message.success(`${statusText}成功`);
              this.loadList(this.pageParams);
            })
            .catch((err) => {
              this.$message.error(err.message);
              row.generalStatus == 0
                ? (row.generalStatus = 1)
                : (row.generalStatus = 0);
            });
        })
        .catch(() => {
          row.generalStatus == 0
            ? (row.generalStatus = 1)
            : (row.generalStatus = 0);
        });
    },
    /**
     * 导出
     */
    async onDownExcel() {
      let { total, pageParams } = this;
      let requestArr = [];
      let obj = JSON.parse(JSON.stringify(pageParams));
      obj.current = 1;
      while (requestArr.length < total) {
        let e = await findpageList(obj);
        requestArr = [...requestArr, ...e.data.list];
        obj.current++;
      }
      let excelData = "\uFEFF";
      excelData += "用户名,用户姓名,部门,手机号码,状态,创建时间";
      excelData += "\n";
      requestArr.forEach((e) => {
        excelData += [
          "" + e.username,
          e.realName,
          e.homeName,
          e.phone,
          e.generalStatus === 1 ? "开启" : "禁用",
          e.createTime,
        ].join(",");
        excelData += "\n";
      });
      let date = new Date();
      let fileName = `用户管理表格-${date.getFullYear()}-${String(
        date.getMonth() + 1
      ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
      exportMethod(excelData, fileName);
    },
    /**
     * 获取角色字典
     */
    getRoleIdsDic() {
      // getRoleIds()
      //   .then(res => {
      //     this.roleArray_group = res.data
      //   })
      //   .catch(err => {
      //     this.roleArray_group = []
      //   })
    },
    getCompanyFromDic() {
      // getCompanyDic().then(res => {})
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  width: 99%;
  border:10px solid #F3F4F7 ;
  background: #fff;
  .clears {
    display: flex;
    flex-direction: row;
    align-items: center;
    .buttons {
      height: 30px;
      margin-left: 10px;
    }
  }
  .TreeBox {
    padding: 20px;
    border-right: 1px solid #F1F1F1;
    background-color: #ffffff;
    .titlw{
      font-size: 18px;
      font-weight: bold;
      color: #303133;
      margin-bottom: 20px;
      span{
        width: 3px;
        height: 16px;
        border:1px solid #1082FF;
        margin-right:9px;
      }
    }
  }
  .filter-tree {
    height: calc(100vh - 200px);
    padding-top: 20px;
    overflow: auto;
  }
  .TableBox {
    padding: 10px 10px 0;
    height: calc(100vh - 150px);
    border-radius: 6px;
    background-color: #fff;
    .SearchBox {
      display: flex;
      flex-wrap: wrap;
      ::v-deep.el-form-item {
        margin-bottom: 0px;
      }
    }
    .selectType{
    .el-select{
      width: 100px;
      
    }
    .el-input-group--prepend{
        margin-top: 6px!important;
      }
    }
  }
  .TablePages {
    .AffirmBtn {
      ::v-deep span {
        height: 12px;
        line-height: 12px;
        min-width: 0px;
      }
    }
  }
  .EialogForm {
    .FormBox {
      padding: 0 100px;
      .NotRequired {
        ::v-deep .el-form-item__label {
          padding-left: 10px;
        }
        .el-select {
          width: 100%;
        }
      }
    }
  }
  .TableTool {
    display: flex;
    justify-content: space-between;
  }
}
.detil-code{
  padding-left: 10px;
  font-size: 16px;
  height: 16px;
  line-height: 16px;
  color: #000000;
  position: relative;
  margin-bottom: 20px;
}
.detil-code:before{
  content: '';
  position: absolute;
  width: 3px;
  height: 16px;
  background-color: #007AFF;
  top: 0;
  left: 0;
}
</style>
